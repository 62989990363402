.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  padding-bottom: 40px;
}

.footer {
  height: 30px;
  width: 100%;

  margin: 0;
  padding-bottom: 10px;

  position: fixed;
  bottom: 0;

  text-align: center;
  font-size: smaller;
}

.error-text {
  color: lightcoral;
  font-size: small;
}

.image-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
}

.image-face {
  width: 40vw;
}

.image-body {
  width: 80vw;
}
